import { graphql, Link } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import Posts from "../components/posts"
import SEO from "../components/seo"
import "./blog-tag.scss"

const blogTagTemplate = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const tag = data.tagsYaml
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={tag.name} description={tag.description} />

      <h2>
        Tag <Link to={tag.fields.slug} className="blog-tag-name">{tag.name}</Link>
      </h2>

      {!!tag.description &&
        <p className="blog-tag-description">{tag.description}</p>
      }

      {(posts.length === 0) &&
        <div>No posts for this tag</div>
      }

      {(posts.length > 0) &&
        <div className="blog-tag-posts">
          <Posts posts={posts} />
        </div>
      }
    </Layout>
  )
}

export default blogTagTemplate

export const pageQuery = graphql`
  query BlogTagById(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    tagsYaml(id: { eq: $id }) {
      name
      description
      fields {
        slug
      }
    }
    allMarkdownRemark(filter: {frontmatter: {tags: {elemMatch: {id: { eq: $id }}}}}, sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          authors {
            name
            fields {
              slug
            }
          }
        }
      }
    }
  }
`
